{
  "auth": {
    "agree": "Я згоден з",
    "createAccount": "Створити обліковий запис",
    "createNewAccount": "Створити новий обліковий запис",
    "telephone": "Телефон",
    "login": "Вхід",
    "password": "Пароль",
    "recover_password": "Відновити пароль",
    "sign_up": "Зареєструватися",
    "create": "Створити акаунт",
    "have_an_account": "Є обліковий запис?",
    "forgot_password": "Забули пароль?",
    "keep_logged_in": "Залишатися в системі",
    "termsOfUse": "Умови використання.",
    "reset_password": "Скинути пароль",
    "firstname": "Ім'я",
    "lastname": "Прізвище",
    "repeat_password": "Повторіть пароль",
    "send_code": "Надіслати код",
    "code": "код",
    "phone_conform": {
      "title": "Підтвердіть номер телефону",
      "des": "Код підтвердження було відправлено як SMS на номер: {phoneNumber}. Діє протягом 10 хв.",
      "send_again": "Відправити повторно "
    },
    "confrim_lable": "Код відправлений Вам до Telegram або SMS"
  },
  "404": {
    "title": "Ця сторінка виїхала на риболовлю.",
    "text": "Якщо ви вважаєте, що це не так, будь ласка, надішліть нам повідомлення на ",
    "back_button": "Повернутися на головну"
  },
  "typography": {
    "primary": "Основні текстові стилі",
    "secondary": "Другорядні текстові стилі"
  },
  "orders": {
    "title": "Замовлення",
    "new_chat": "Почати чат з покупцем",
    "select_status": "Виберіть Статус",
    "search_types": {
      "archive": "Шукати в архіві",
      "ttn": "ТТН для пошуку",
      "receiver": "Ім'я отримувача для пошуку",
      "receiver_phone": "Номер телефону отримувача для пошуку",
      "order_id": "Номер замовлення для пошуку"
    },
    "detail": {
      "delivery_method": "Оберіть спосіб доставки",
      "products_item": "Товари на замовлення",
      "not_full": "Не повне замовлення",
      "order_number": "Номер замовлення",
      "order_sum": "Сума замовлення",
      "order_date": "Дата замовлення",
      "payment_method": "Оберіть спосіб оплати",
      "recipient": "Отримувач",
      "order_payment_method": "Метод оплати",
      "telephone": "Номер телефону",
      "ttn": "ТТН замовлення",
      "ttn_return": "ТТН  замовлення",
      "delivery_address": "Доставка",
      "comment": "Коментар до замовлення",
      "sum": "Сума",
      "ttn_sum": "Сума післяплати",
      "earnings": "Заробіток",
      "shelves": "Полиця:"
    },

    "versions": "Версії",
    "setupRemoteConnections": "Налаштування віддалених з'єднань",
    "currentVisitors": "Поточні відвідувачі",
    "navigationLayout": "Макет навігації",
    "topBarButton": "Верхня панель",
    "sideBarButton": "Бічна панель"
  },
  "store": {
    "title": "Мої магазини",
    "emty_store": "У вас немає магазинів",
    "add_store": "Додавання магазину",
    "edite_store": "Редагувати магазин",

    "article": {
      "title": "Статті",
      "form": {
        "name": {
          "lable": "Заголовок",
          "title": "Заголовок",
          "required": "Заголовок не може бути порожнім."
        },
        "show_in_header": {
          "lable": "Показати вгорі"
        },
        "show_in_footer": {
          "lable": "Показати знизу"
        },
        "sort_order": {
          "lable": "Порядок сортування",
          "title": "Порядок сортування",
          "format": "Порядок сортування має бути цифр",
          "required": "Порядок сортування не може бути порожнім."
        },
        "description": {
          "lable": "Опис",
          "title": "Ваш опис",
          "required": "Опис не може бути порожнім."
        }
      }
    }
  },
  "products": {
    "title": "Продукти",
    "sizes_list": "Список Ростовок",
    "sizes": "Ростовки",
    "size": "Розмір",
    "actions": "Действие",
    "import": "Імпорт",
    "imports": "Імпорт продуктів",
    "import_tabel": {
      "file_name": "Назва файлу",
      "total": "Загальна кількість",
      "file_url": "URL файлу",
      "status": "Статус",
      "status_values": {
        "finished": "Завершено",
        "in_progress": "В процесі",
        "failed": "Не вдалося"
      }
    },
    "imports_list": "Список імпорту",
    "import_statuses": {
      "waiting_products": "Очікування продукту",
      "failed_products": "Не вдалося завантажити продукти",
      "total_products": "Загальна кількість продуктів"
    },

    "product": "Продукт",
    "form": {
      "name": "ім'я",
      "description": "опис",
      "model": "артикул товару",
      "price": "ціна РРЦ",
      "drop_price": "Дроп ціна",
      "price_pre_pay": "ціна передоплати",
      "option": "опція",
      "value": "Розмір",
      "quantity": "кількість",
      "add_image": "Додати зображення",
      "image": {
        "title": "Додавайте свої фото та відео, щоб ваш товар мав краще представлення.",
        "drop": "перетягніть файли сюди, щоб завантажити",
        "des": " Завантажте відео свого товару, щоб надати потенційним покупцям більше інформації про нього. Відео може допомогти краще продемонструвати особливості, функціонал та переваги вашого продукту. Підтримуються формати MP4,MOV. Максимальний розмір файлу – 50 МБ."
      },
      "main_image": {
        "title": "Головне фото",
        "drop": "перетягніть фото сюди, щоб завантажити"
      }
    },
    "tabs": {
      "main": "Основні",
      "sizes": "Розміри",
      "photo": "Фото"
    }
  },
  "balance": {
    "title": "Інформація про баланс",
    "balance_up": "Поповнити",
    "balance_to_pay": "Доступно до виплати",
    "transactions": {
      "title": "Транзакції",
      "status_pay": "Поповнення балансу"
    }
  },
  "payouts": {
    "title": "Виплати",
    "search_by_ttn": "Пошук по ТТН",
    "phone": "Введіть телефон",
    "total_amount": "Загальна сума для виплати",
    "no_data": "Виплат немає",
    "pay": "Виплатити",
    "transaction": {
      "all": "Усі транзакції користувача",
      "title": "Транзакція",
      "archive": "Архів",
      "balance_to_pay": "Баланс до виплати",
      "order": "Замовлення",
      "amount": "Сума",
      "description": "Опис",
      "paymentOrder": "Оплата замовлення",
      "paymentBalance": "Оплата балансом",
      "refund": "Відшкодування",
      "system": "Системне нарахування",
      "date": "Дата",
      "amout": "Сума післяплати",
      "price_site": "Наша ціна на сайті",
      "earn": "Заробіток"
    },
    "userDetails": {
      "fullName": "ПІБ",
      "cardNumber": "Номер карти",
      "phoneNumber": "Номер телефону",
      "email": "Email",
      "dropshipping": "Дропшипінг"
    }
  },
  "payment_methods": {
    "title": "Методи оплати",
    "mono_bank": {
      "title": "Моно Банк",
      "description": "Використовуйте API ключ Моно банка для безпечних платежів."
    },
    "payment_by_requisites": {
      "title": "Оплата за реквізитами",
      "description": "Введіть реквізити для завершення оплати замовлення.",
      "lable": "Реквізити"
    },
    "balance_payment": {
      "title": "Оплата балансом",
      "description": "Оплачуйте за допомогою доступного балансу."
    },
    "cash_on_delivery": {
      "title": "Накладений платіж",
      "description": "Оплата при доставці із зазначенням суми."
    }
  },
  "articles": {
    "title": "Інформаційні статті",
    "article": "Стаття"
  },
  "settings": {
    "title": "Налаштування",
    "confirm": "Підтвердити обліковий запис",
    "general": "Загальні переваги",
    "theme": "Тема",
    "reset_password": "Скинути пароль",
    "member_since": "Учасник з",
    "new_password": "Новий пароль *",
    "confirmation_password": "Повторити новий пароль *",
    "tg_conection": "Підключення Telegram",
    "connected": "Підключено",
    "password": {
      "required": "Поле для нового пароля є обов'язковим",
      "min_length": "Повинен містити щонайменше 6 символів",
      "unique_chars": "Повинен містити щонайменше 6 унікальних символів",
      "same_as_old": "Новий пароль не може бути таким же, як старий",
      "repeat_required": "Поле для повторення нового пароля є обов'язковим",
      "mismatch": "Підтвердження пароля не збігається з новим паролем"
    }
  },
  "language": {
    "title": "Мова",
    "brazilian_portuguese": "Португальська (Бразилія)",
    "english": "Англійська",
    "spanish": "Іспанська",
    "simplified_chinese": "Спрощений китайський",
    "persian": "Перська"
  },
  "menu": {
    "auth": "Аутентифікація",
    "buttons": "Кнопки",
    "timelines": "Часові лінії",
    "orders": "Замовлення",
    "billing": "Виставлення рахунків",
    "login": "Увійти",
    "articles": "Інформаційні статті",
    "chat": "Чаты",
    "preferences": "Обліковий запис",
    "payments": "Платежі",
    "payouts": "Виплати",
    "balance": "Баланс",
    "settings": "Налаштування",
    "settings-app": "Налаштування додатка",
    "pricing-plans": "Тарифні плани",
    "payment-methods": "Методи оплати",
    "signup": "Реєстрація",
    "recover-password": "Відновлення паролю",
    "recover-password-email": "Відновлення паролю за ел. поштою",
    "404": "404",
    "faq": "FAQ",
    "nova": "Нова Почта",
    "nova-api": "API",
    "nova-sender": "Відправник",
    "users": "Користувачі",
    "products": "Продукти",
    "stores": "Магазини"
  },
  "messages": {
    "all": "Переглянути всі повідомлення",
    "new": "Нові повідомлення від {name}",
    "mark_as_read": "Позначити як прочитане"
  },
  "navbar": {
    "messageUs": "Питання з веб-розробки:",
    "repository": "GitHub Репозиторій"
  },
  "notifications": {
    "title": "Сповіщення, які ви отримуєте",
    "email": {
      "title": "Отримувати Email повідомлення",
      "subtitle": "Інформаційні листи з актуальною інформацією щодо новинок, акцій тощо"
    },
    "push": {
      "title": "Push Повідомлення",
      "subtitle": ""
    },
    "product": {
      "title": "Сповіщення по товарам",
      "subtitle": "Персональные рекомендации, скидки или сезонные предложения"
    },
    "orders": {
      "title": "Повідомлення про замовлення",
      "subtitle": "Інформування щодо актуальної інформації по замовленню"
    },
    "system": {
      "title": "Системні сповіщення",
      "subtitle": "Повідомлення про актуальні оновлення чи зміни в додатку"
    },
    "all": "Переглянути всі сповіщення",
    "less": "Показати менше сповіщень",
    "mark_as_read": "Позначити як прочитане",
    "sentMessage": "відправив вам повідомлення",
    "uploadedZip": "завантажив новий Zip-файл з {type}",
    "startedTopic": "почав нову тему"
  },
  "user": {
    "balance": "Баланс",
    "language": "Змінити мову",
    "logout": "Вийти",
    "profile": "Профіль",
    "settings": "Налаштування",
    "billing": "Виставлення рахунків",
    "faq": "FAQ",
    "helpAndSupport": "Допомога і підтримка",
    "products": "Продукти",
    "account": "Обліковий запис",
    "explore": "Досліджувати"
  },
  "treeView": {
    "basic": "Базовий",
    "icons": "Іконки",
    "selectable": "Вибирається",
    "editable": "Редагується",
    "advanced": "Продвинутий"
  },
  "chat": {
    "title": "Чат",
    "not_selected": "Чат не вибрано",
    "empty_chats": "У вас поки немає чатів.",
    "sendButton": "Відправити"
  },
  "senders": {
    "api_key": "API ключ",
    "sender": "Відправник",
    "contact_person": "Контактна особа",
    "region": "Область",
    "city": "Місто",
    "branch": "Відділення",
    "sendButton": "Відправити"
  },
  "apiKeys": {
    "title": "API ключі",
    "listTitle": "Список API ключів",
    "addApiButton": "Додати API",
    "saveApiButton": "Зберегти",
    "addApiTitle": "Додати API",
    "editApiTitle": "Редагувати API",
    "description": "Опис"
  },
  "spacingPlayground": {
    "value": "Значення",
    "margin": "Відступ",
    "padding": "Внутрішній відступ"
  },
  "spacing": {
    "title": "Відступи"
  },
  "cards": {
    "cards": "Карти",
    "fixed": "Фіксовані",
    "floating": "Плаваючі",
    "contentText": "Унікальні смуги зебр роблять їх одним з найвідоміших тварин для людей.",
    "contentTextLong": "Унікальні смуги зебр роблять їх одним з найвідоміших тварин для людей. Вони зустрічаються в різних місцях мешкання, таких як луки, савани, лісові масиви, колючі кущі, гори і прибережні пагорби. Різні антропогенні фактори суттєво позначилися на популяціях зебр, зокрема, полювання на шкури та знищення місць мешкання. Зебри Греві та гірські зебри перебувають під загрозою зникнення. Тим часом як звичайні зебри набагато поширеніші, один підвид, квагга.",
    "rowHeight": "Висота рядка",
    "title": {
      "default": "За замовчуванням",
      "withControls": "З елементами управління",
      "customHeader": "З власними заголовками",
      "withoutHeader": "Без заголовка",
      "withImage": "З зображенням",
      "withTitleOnImage": "З заголовком на зображенні",
      "withCustomTitleOnImage": "Зі своїм заголовком на зображенні",
      "withStripe": "З смугою",
      "withBackground": "З фоном"
    },
    "button": {
      "main": "Основна",
      "cancel": "Скасувати",
      "showMore": "Показати більше",
      "readMore": "Показати більше"
    },
    "link": {
      "edit": "Редагувати",
      "setAsDefault": "Встановити за замовчуванням",
      "delete": "Видалити",
      "traveling": "Подорож",
      "france": "Франція",
      "review": "Огляд",
      "feedback": "Залишити відгук",
      "readFull": "Читати повну статтю",
      "secondaryAction": "Другорядна дія",
      "action1": "Дія 1",
      "action2": "Дія 2"
    }
  },
  "colors": {
    "themeColors": "Кольори теми",
    "extraColors": "Додаткові кольори",
    "gradients": {
      "basic": {
        "title": "Градієнти кнопок"
      },
      "hovered": {
        "title": "Градієнти при наведенні",
        "text": "Засвічування на 15% застосовується до вихідного стилю (градієнт або плоский колір) для стану наведення."
      },
      "pressed": {
        "title": "Градієнти при натисканні",
        "text": "Затемнення на 15% застосовується до вихідного стилю (градієнт або плоский колір) для стану натискання."
      }
    }
  },
  "tabs": {
    "alignment": "Вирівнювання вкладок",
    "overflow": "Переповнення вкладок",
    "hidden": "Вкладки з прихованим повзунком",
    "grow": "Розширення вкладок"
  },
  "helpAndSupport": "Допомога і підтримка",
  "aboutVuesticAdmin": "Про Vuestic Admin",
  "search": {
    "placeholder": "Пошук..."
  },
  "buttonSelect": {
    "dark": "Темний",
    "light": "Світлий"
  },
  "validation": {
    "required": "Це поле є обов'язковим"
  },
  "system": {
    "edited": "відредаговано",
    "no_data": "немає даних",
    "confirmed": "Підтверджено",
    "button": {
      "confirm": "Підтвердити",
      "back": "Назад",
      "close": "Закрити",
      "next": "Продовжити",
      "add": "Додати",
      "filter": "Фільтр",
      "cancel": "Скасувати",
      "clear": "Очистити",
      "edit": "Редагувати",
      "save": "Зберегти"
    },
    "alert": {
      "code": "На ваш номер надіслано код, будь ласка підтвердіть",
      "phone": {
        "confirmed": "Номер підтверджений"
      }
    }
  }
}
