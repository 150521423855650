import { defineStore } from 'pinia'
import api from '../services/api/axiosConfig'
import router from '../router'

interface User {}

interface AuthState {
  user: User | null
  returnUrl: string | null
}

interface RegisterForm {
  firstname: string
  lastname: string
  telephone: string
  // password: string
  // password_confirmation: string
  // supplier_manager_contact: string
  // supplier_np_api_key: string
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthState => ({
    user: JSON.parse(localStorage.getItem('user') || 'null'),
    returnUrl: null,
  }),
  actions: {
    async login(telephone: any) {
      const response: any = await api.post<any>(`/suppliers/v2/auth/login`, { telephone })
      return response
    },
    async loginWithPassword(data: any) {
      const response: any = await api.post<any>(`/suppliers/v1/auth/login`, data)

      if (response) {
        const user = {
          data: response.data,
          token: response.token,
        }
        if (user.token) {
          localStorage.setItem('user', JSON.stringify(user))
          router.push(this.returnUrl || '/orders')
          window.location.reload()
        }
        return response
      }
    },

    async confirmAuth(code: number) {
      try {
        const response: any = await api.post<any>('/suppliers/v2/auth/confirm-code', { code })

        if (response) {
          const user = {
            data: response.data,
            token: response.token,
          }
          if (user.token) {
            localStorage.setItem('user', JSON.stringify(user))
            router.push(this.returnUrl || '/orders')
            window.location.reload()
          }
          return response
        }
      } catch (error: any) {
        console.error('Error fetching articles:', error)
        return error
      }
    },

    async register(formData: RegisterForm) {
      const res = await api.post<any>(`/suppliers/v2/auth/register`, formData)

      return res
    },

    async forgotPassword(telephone: string) {
      const res: any = await api.post<any>(`/suppliers/v1/auth/forgotten`, { telephone })
      if (res) router.push({ name: 'recover-password-code', params: { code: res.code, telephone } })

      return res
    },
    async forgotConfirm(data: any) {
      const res: any = await api.post<any>(`/suppliers/v1/auth/forgotten-confirm`, data)

      return res
    },
    async getCountriesCode(page: number = 1, search: string = '') {
      try {
        const res: any = await api.get<any>(`/v3/countries?page=${page}&search=${search}`)

        return res
      } catch (error: any) {
        return error
      }
    },

    logout() {
      this.user = null
      localStorage.removeItem('user')
      router.push('/auth/login')
      window.location.reload()
    },
  },
})
