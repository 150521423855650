export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'orders',
      displayName: 'menu.orders',
      meta: {
        icon: 'list_alt',
      },
    },
    {
      name: 'products',
      displayName: 'menu.products',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'stores',
      displayName: 'menu.stores',
      meta: {
        icon: 'store',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'payments',
      },
      children: [
        {
          name: 'balance',
          displayName: 'menu.balance',
        },
        {
          name: 'payouts',
          displayName: 'menu.payouts',
        },
      ],
    },
    {
      name: 'nova',
      displayName: 'menu.nova',
      meta: {
        icon: 'inventory',
      },
      children: [
        {
          name: 'api',
          displayName: 'menu.nova-api',
        },
        {
          name: 'sender',
          displayName: 'menu.nova-sender',
        },
      ],
    },
    {
      name: 'chat',
      displayName: 'menu.chat',
      meta: {
        icon: 'chat',
      },
    },
    {
      name: 'articles',
      displayName: 'menu.articles',
      meta: {
        icon: 'article',
      },
    },
    {
      name: 'payment-methods',
      displayName: 'menu.payment-methods',
      meta: {
        icon: 'wallet',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
      children: [
        {
          name: 'preferences',
          displayName: 'menu.preferences',
        },
        {
          name: 'settings',
          displayName: 'menu.settings-app',
        },
      ],
    },
  ] as INavigationRoute[],
}
