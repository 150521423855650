import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'orders' },
    meta: { requiresAuth: true },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'orders' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'orders',
        path: 'orders',
        component: () => import('../pages/admin/orders/Orders.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'products',
        path: 'products',
        component: () => import('../pages/products/ProductPage.vue'),
      },
      {
        name: 'stores',
        path: 'stores',
        component: () => import('../pages/stores/StorePage.vue'),
      },
      {
        name: 'nova',
        path: '/nova',
        component: RouteViewComponent,
        children: [
          {
            name: 'api',
            path: 'api',
            component: () => import('../pages/nova/ApiPage.vue'),
          },
          {
            name: 'sender',
            path: 'sender',
            component: () => import('../pages/nova/SenderPage.vue'),
          },
        ],
      },
      {
        name: 'chat',
        path: 'chat',
        component: () => import('../pages/chat/RoomPage.vue'),
        children: [
          {
            path: ':chatId',
            component: () => import('../pages/chat/ChatPage.vue'),
            props: true,
          },
        ],
      },
      {
        name: 'articles',
        path: 'articles',
        component: () => import('../pages/articles/Articles.vue'),
      },
      {
        name: 'payment-methods',
        path: 'payment-methods',
        component: () => import('../pages/payment-methods/MethodsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'balance',
            path: 'balance',
            component: () => import('../pages/balance/BillingPage.vue'),
          },
          {
            name: 'payouts',
            path: 'payouts',
            component: () => import('../pages/balance/PayoutsPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    props: true,
    meta: { requiresAuth: false },

    children: [
      {
        name: 'main',
        path: '',
        component: () => import('../pages/auth/Main.vue'),
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
        props: true,
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'phone-confirmation',
        path: 'phone-confirmation',
        props: true,
        component: () => import('../pages/auth/PhoneConfirmation.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-code',
        path: 'recover-password-code',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
        props: true,
      },
      {
        name: 'login-with-password',
        path: 'with-password',
        component: () => import('../pages/auth/LoginWithPassword.vue'),
        props: true,
      },
      {
        path: 'auth',
        redirect: { name: 'main' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isAuthenticated = localStorage.getItem('user') !== null

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'main' })
  } else if (isAuthenticated && to.path.includes('auth')) {
    next({ name: 'orders' })
  } else {
    next()
  }
})

export default router
